.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.driver-tag {
  /*border: 1px solid black;*/
  border-radius: 5px;
  padding: 1px 3px;
  display: inline-flex;
}

.pac-container {
  z-index: 2000 !important;
}


/*input[type=date]:required:invalid::-webkit-datetime-edit {*/
/*  color: transparent;*/
/*}*/
/*input[type=date]:focus::-webkit-datetime-edit {*/
/*  color: black !important;*/
/*}*/

/*input[type="date"]::before {*/
/*  content: attr(placeholder);*/
/*  position: absolute;*/
/*  color: #999999;*/
/*}*/

/*input[type="date"]:focus::before,*/
/*input[type="date"]:valid::before {*/
/*  content: "";*/
/*}*/

/*input[type="date"] {*/
/*  color: #ffffff;*/
/*  content: ""*/
/*}*/

/*input.no-placeholder[value=""] {*/
/*  color: #ffffff;*/
/*}*/

/*input[type="date"]:focus,*/
/*input[type="date"]:valid {*/
/*  color: #000000;*/
/*}*/

input.no-placeholder::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input.no-placeholder:focus::before,
input.no-placeholder:valid::before {
  content: "";
}

input.no-placeholder {
  color: transparent;
}

input.no-placeholder:valid {
  color: #000000;
}

input.no-placeholder[value=""] {
  color: #ffffff;
}

input.no-placeholder:focus {
  color: #000000;
}

.MuiDataGrid-cell div div {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /*max-height: 48px;*/
  white-space: normal;
  text-align: left;
}

#map {
  min-height: 500px;
  width: 100%;
}

.maps-info-window {
  text-align: left;
}

/*:root {*/
/*  --amplify-colors-brand-primary-10: var(--amplify-colors-green-10);*/
/*  --amplify-colors-brand-primary-20: var(--amplify-colors-green-20);*/
/*  --amplify-colors-brand-primary-40: var(--amplify-colors-green-40);*/
/*  --amplify-colors-brand-primary-60: var(--amplify-colors-green-60);*/
/*  --amplify-colors-brand-primary-80: var(--amplify-colors-green-80);*/
/*  --amplify-colors-brand-primary-90: var(--amplify-colors-green-90);*/
/*  --amplify-colors-brand-primary-100: var(--amplify-colors-green-100);*/
/*  --amplify-components-button-primary-background-color: var(--amplify-colors-green-100);*/
/*  --amplify-components-button-primary-color: purple;*/
/*}*/

/*[data-amplify-authenticator] {*/
/*  !*--amplify-colors-background-primary: var(--amplify-colors-neutral-90);*!*/
/*  !*--amplify-colors-background-secondary: var(--amplify-colors-neutral-100);*!*/
/*  !*--amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);*!*/
/*  !*--amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);*!*/
/*  !*--amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);*!*/
/*  !*--amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);*!*/
/*  !*--amplify-colors-font-interactive: var(--amplify-colors-white);*!*/
/*  !*--amplify-components-tabs-item-active-color: var(--amplify-colors-white);*!*/
/*  !*--amplify-components-tabs-item-focus-color: var(--amplify-colors-white);*!*/
/*}*/

/*.amplify-button--primary {*/
/*  background-color: purple;*/
/*}*/
