body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*[data-amplify-authenticator] {*/
/*  !*--amplify-colors-background-primary: var(--amplify-colors-neutral-90);*!*/
/*  !*--amplify-colors-background-secondary: var(--amplify-colors-neutral-100);*!*/
/*  !*--amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);*!*/
/*  --amplify-colors-brand-primary-80: var(--amplify-colors-pink-20);*/
/*  --amplify-colors-brand-primary-90: var(--amplify-colors-green-20);*/
/*  --amplify-colors-brand-primary-100: var(--amplify-colors-green-10);*/
/*  !*--amplify-colors-font-interactive: var(--amplify-colors-white);*!*/
/*  !*--amplify-components-tabs-item-active-color: var(--amplify-colors-white);*!*/
/*  !*--amplify-components-tabs-item-focus-color: var(--amplify-colors-white);*!*/
/*  !*--amplify-components-button-primary-color: purple;*!*/
/*}*/
